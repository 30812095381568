<template>
  <Layout>
    <div class="main-bid">
      <div class="base-width">
        <Search v-ani.fade-up placeholder="请输入关键词搜索" :type="false" @change="handleSearch" />
      </div>
      <div class="c-layout-4">
        <div class="base-width">
          <!-- 导航 -->
          <Cate :list="cateList" curId="" path='/news' v-ani.fade-right class="col-1" />
          <!-- 招标项目情况 -->
          <HomeNews2 v-ani.fade-left class="col-2" :data="biddingBase" />
          <!-- 中标排行版 -->
          <HomeRank class="col-3" v-ani.fade-left :list="bid_ranking" />
        </div>
      </div>
      <div class="base-width">
        <!-- 参数 -->
        <Params :baseObj="catList" @change="handleChangeCat" v-ani.fade-up type="Bid" />
        <!-- 最新招投标-列表 -->
        <BidList @showCode='showCode = true' :list="biddingData" :msgList="msgList" type="Bid"></BidList>
        <!-- 分页 -->
        <Pagination :page="pager.page" :size="pager.size" :total="total" v-ani.fade-up @change="handlePager"
          :key="pagKey" />
      </div>
    </div>
    <!-- 二维码弹窗 -->
    <BasePopCode :show.sync="showCode"></BasePopCode>
  </Layout>
</template>

<script>
import { BiddingBaseApi, BiddingListApi, BiddingMsgListApi, BiddingCatListApi } from "@/request/api/bid";
export default {
  data() {
    return {
      cateList: [
        { cat_id: '', cat_name: '招投标', link: '/bid/bid' },
        { cat_id: '1', cat_name: '招采大厅', link: '/bid/material' },
      ],
      pager: {
        page: 1,
        page_num: 10,
      },
      keywords: "",
      total: 0,
      showCode: false,
      biddingBase: [],
      bid_ranking: [],
      biddingData: [],
      msgList: [],
      catList: {},
      cat_id: "",
      keywords: "",
      pagKey: 0,
    };
  },
  mounted() {
    this.getBiddingBase();
    this.biddingList();
    this.biddingMsgList();
    this.biddingCatList();
  },
  methods: {
    handleSearch(e) {
      this.keywords = e;
      this.biddingList();
    },
    // 参数
    handleChange(params) {
      console.log(params);
    },
    // 分页
    handlePager(page) {
      this.pager.page = page;
      this.biddingList();
    },
    //招标项目情况
    getBiddingBase() {
      BiddingBaseApi().then(res => {
        if (res.status == 200) {
          this.biddingBase = res.data.project_situation;
          res.data.bid_ranking.map(item => {
            let obj = {
              title: item
            }
            this.bid_ranking.push(obj);
          });
          this.biddingBase = this.biddingBase.map(item => {
            item.article = item.list;
            delete item.list;
            return item;
          })
        }
      })
    },
    //最新招投标
    biddingList() {
      let params = {
        ...this.pager,
        cat_id: this.cat_id,
        keywords: this.keywords
      }
      BiddingListApi(params).then(res => {
        if (res.status == 200) {
          this.biddingData = res.data.list;
          this.total = res.data.count;
          this.pagKey++;
        }
      })
    },
    //消息列表
    biddingMsgList() {
      BiddingMsgListApi().then(res => {
        if (res.status == 200) {
          this.msgList = res.data
        }
      })
    },
    //分类说明
    biddingCatList() {
      BiddingCatListApi().then(res => {
        if (res.status == 200) {
          this.catList = res.data;
        }
      })
    },
    handleChangeCat(e) {
      this.cat_id = e.val.cat_id;
      this.biddingList();
    }
  }
};
</script>

<style lang="scss" scoped>
.c-prod-params {
  margin-top: .41rem;
  padding-top: .15rem;

  ::v-deep {
    .item {
      padding-top: .15rem;
      padding-bottom: .15rem;
    }

    .item .list .li {
      line-height: .4rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    .item .list .li.on {
      font-size: .18rem;
    }
  }
}

.md-pagination {
  margin: .6rem 0;
}
</style>